<template>
  <div>
    <ListingLayout
      :sub-screen="1"
      :is-loading="listingLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :bread-crumbs="false"
      no-filters
      show-date
      :date-custom-options="true"
      date-validation
      telematic-massage-rows
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:id="{ item }">
        <div v-if="authUser.tenant_id">
          {{ item.id }}
        </div>
        <div
          v-else
          class="messageDetail"
          @click="showMsgDetail(item)"
        >
          {{ item.id }}
        </div>
      </template>
      <template v-slot:total_distance="{ item }">
        <div>
          {{ item.total_distance || "-" }}
        </div>
      </template>

      <!-- add Filters -->
      <!-- <template #moreFilters> </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div class="d-flex">
          <div v-if="list?.length > 0">
            <refresh-button
              :is-loading="listingLoading"
              @reload="fetchData"
            />
          </div>
        </div>
      </template>
      <!-- Action Modals -->

      <!-- description -->
      <template #description>
        <div class="wrapper">
          <div class="leftOverviewCard">
            <h4 class="cardHeading">
              Journey Summary
            </h4>
            <span v-if="detailsLoading">
              <v-skeleton-loader
                v-for="i in 3"
                :key="i"
                type="list-item-two-line"
                style="flex: 1"
              />
            </span>
            <ul
              v-else
              class="overviewDetailsList"
            >
              <!-- Transit -->
              <template v-for="(r, i) in transit">
                <li :key="`${i}-${r.name}`">
                  <span
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                    class="cardLabel"
                  >
                    {{ r.name }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="ml-2 open-link"
                          v-on="on"
                        >mdi-information</v-icon>
                        <!-- Vuetify Icon -->
                      </template>
                      <span>Indicates that the total time a vehicle spends driving
                        and idling with the ignition ON</span>
                    </v-tooltip>
                  </span>
                  <ul>
                    <li>
                      <span class="cardLabel">Duration </span>
                      <span class="cardTextData">
                        {{ r.data.formatted_duration }}
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Total Distance </span>
                      <span class="cardTextData">
                        {{ r.data.total_distance }} (KM)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Average Speed </span>
                      <span class="cardTextData">
                        {{ r.data.average_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Max Speed </span>
                      <span class="cardTextData">
                        {{ r.data.max_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Duration Percentage </span>
                      <span class="cardTextData">
                        {{ r.data.duration_percentage }}%
                      </span>
                    </li>
                  </ul>
                </li>
              </template>

              <!-- Driving -->
              <template v-for="(r, i) in driving">
                <li :key="`${i}-${r.name}`">
                  <span
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                    class="cardLabel"
                  >
                    {{ r.name }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="ml-2 open-link"
                          v-on="on"
                        >mdi-information</v-icon>
                        <!-- Vuetify Icon -->
                      </template>
                      <span>Indicates that the vehicle is actively in motion</span>
                    </v-tooltip>
                  </span>
                  <ul>
                    <li>
                      <span class="cardLabel">Duration </span>
                      <span class="cardTextData">
                        {{ r.data.formatted_duration }}
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Total Distance </span>
                      <span class="cardTextData">
                        {{ r.data.total_distance }} (KM)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Average Speed </span>
                      <span class="cardTextData">
                        {{ r.data.average_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Max Speed </span>
                      <span class="cardTextData">
                        {{ r.data.max_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Duration Percentage </span>
                      <span class="cardTextData">
                        {{ r.data.duration_percentage }}%
                      </span>
                    </li>
                  </ul>
                </li>
              </template>

              <!-- Idle -->
              <template v-for="(r, i) in idle">
                <li :key="`${i}-${r.name}`">
                  <span
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                    class="cardLabel"
                  >
                    {{ r.name }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="ml-2 open-link"
                          v-on="on"
                        >mdi-information</v-icon>
                        <!-- Vuetify Icon -->
                      </template>
                      <span>Indicates that the vehicle is stationary while the
                        Ignition remains ON</span>
                    </v-tooltip>
                  </span>
                  <ul>
                    <li>
                      <span class="cardLabel">Duration </span>
                      <span class="cardTextData">
                        {{ r.data.formatted_duration }}
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Total Distance </span>
                      <span class="cardTextData">
                        {{ r.data.total_distance }} (KM)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Average Speed </span>
                      <span class="cardTextData">
                        {{ r.data.average_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Max Speed </span>
                      <span class="cardTextData">
                        {{ r.data.max_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Duration Percentage </span>
                      <span class="cardTextData">
                        {{ r.data.duration_percentage }}%
                      </span>
                    </li>
                  </ul>
                </li>
              </template>

              <!-- Parked -->
              <template v-for="(r, i) in parked">
                <li :key="`${i}-${r.name}`">
                  <span
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                    class="cardLabel"
                  >
                    {{ r.name }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="ml-2 open-link"
                          v-on="on"
                        >mdi-information</v-icon>
                        <!-- Vuetify Icon -->
                      </template>
                      <span>Indicates that the vehicle is stationary with the
                        Ignition turned off</span>
                    </v-tooltip>
                  </span>
                  <ul>
                    <li>
                      <span class="cardLabel">Duration </span>
                      <span class="cardTextData">
                        {{ r.data.formatted_duration }}
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Total Distance </span>
                      <span class="cardTextData">
                        {{ r.data.total_distance }} (KM)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Average Speed </span>
                      <span class="cardTextData">
                        {{ r.data.average_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Max Speed </span>
                      <span class="cardTextData">
                        {{ r.data.max_speed }} (KM/Hr)
                      </span>
                    </li>
                    <li>
                      <span class="cardLabel">Duration Percentage </span>
                      <span class="cardTextData">
                        {{ r.data.duration_percentage }}%
                      </span>
                    </li>
                  </ul>
                </li>
              </template>
            </ul>
          </div>
          <div class="summaryRightCard">
            <div class="rightOverviewCard">
              <h4 class="cardHeading">
                Vehicle Usage
              </h4>
              <v-skeleton-loader
                v-if="detailsLoading"
                type="image"
                width="100%"
              />
              <div
                v-else
                class="semiPieChart"
              >
                <ApexSemiPieChart
                  :height="'280px'"
                  :width="'100%'"
                  :titles="chartData?.titles"
                  :data="chartData?.data"
                  :percentage="chartData?.percentage"
                />
              </div>
            </div>
            <div class="rightOverviewCard">
              <h4 class="cardHeading">
                Map
              </h4>
              <v-skeleton-loader
                v-if="isMap"
                type="image"
                width="100%"
                height="600px"
              />
              <template
                v-else-if="
                  mapData.path.length == 0 && !isMap && lastParked.length > 0
                "
              >
                <vue-leaf-let
                  :bound="lastParked[0]?.points ? [lastParked[0]?.points] : []"
                  height="600px"
                >
                  <template #markers>
                    <template v-for="(evtMkr, i) in lastParked">
                      <l-marker
                        :key="`${i}-eventParked`"
                        :lat-lng="evtMkr?.points"
                        @click="showPopupMarkersParked"
                      >
                        <l-icon>
                          <VehicleMapMarkers
                            name="marker-parked"
                            :status="''"
                          />
                        </l-icon>
                        <l-popup>
                          <div class="popup-box">
                            <div class="title">
                              {{ popup.state }}
                            </div>
                            <div class="">
                              Latitude:
                              <span>{{ popup?.latitude?.toFixed(4) }}</span>
                            </div>
                            <div class="">
                              Longitude:
                              <span>{{ popup?.longitude?.toFixed(4) }}</span>
                            </div>
                            <div class="">
                              Start Time:
                              <span>{{ popup?.start_time }}</span>
                            </div>
                            <div class="">
                              End Time:
                              <span>{{ popup?.end_time }}</span>
                            </div>
                            <div class="">
                              Duration:
                              <span>{{ popup?.formatted_duration }}</span>
                            </div>
                          </div>
                        </l-popup>
                      </l-marker>
                    </template>
                  </template>
                </vue-leaf-let>
              </template>
              <vue-leaf-let
                v-else-if="mapData.path"
                :bound="mapData.path || []"
                height="600px"
              >
                <template #markers>
                  <template>
                    <!-- Map Markers -->
                    <template v-for="(mkr, i) in mapData.marker">
                      <l-marker
                        :key="`${i}-mapMarker`"
                        :lat-lng="mkr"
                      >
                        <l-icon v-if="i !== 0">
                          <VehicleMapMarkers
                            :name="mapData.vehicle_BT"
                            :status="mapData.status"
                          />
                          <!-- :body-type="mapData.vehicle_BT" -->
                        </l-icon>
                      </l-marker>
                    </template>

                    <template v-for="(evtMkr, i) in mapData.event.parked">
                      <l-marker
                        :key="`${i}-eventParked`"
                        :lat-lng="evtMkr?.points"
                        @click="showPopupMarkersParked"
                      >
                        <l-icon>
                          <VehicleMapMarkers
                            name="marker-parked"
                            :status="''"
                          />
                        </l-icon>
                        <l-popup>
                          <div class="popup-box">
                            <div class="title">
                              {{ popup.state }}
                            </div>
                            <div class="">
                              Latitude:
                              <span>{{ popup?.latitude?.toFixed(4) }}</span>
                            </div>
                            <div class="">
                              Longitude:
                              <span>{{ popup?.longitude?.toFixed(4) }}</span>
                            </div>
                            <div class="">
                              Start Time:
                              <span>{{ popup?.start_time }}</span>
                            </div>
                            <div class="">
                              End Time:
                              <span>{{ popup?.end_time }}</span>
                            </div>
                            <div class="">
                              Duration:
                              <span>{{ popup?.formatted_duration }}</span>
                            </div>
                          </div>
                        </l-popup>
                      </l-marker>
                    </template>

                    <template v-for="(evtMkr, i) in mapData.event.idle">
                      <l-marker
                        :key="`${i}-eventIdle`"
                        :lat-lng="evtMkr?.points"
                        @click="showPopupMarkersIdle"
                      >
                        <l-icon>
                          <VehicleMapMarkers
                            name="marker-idle"
                            :status="''"
                          />
                        </l-icon>
                        <l-popup>
                          <div class="popup-box">
                            <div class="title">
                              {{ popup.state }}
                            </div>
                            <div class="">
                              Latitude:
                              <span>{{ popup?.latitude?.toFixed(4) }}</span>
                            </div>
                            <div class="">
                              Longitude:
                              <span>{{ popup?.longitude?.toFixed(4) }}</span>
                            </div>
                            <div class="">
                              Start Time:
                              <span>{{ popup?.start_time }}</span>
                            </div>
                            <div class="">
                              End Time:
                              <span>{{ popup?.end_time }}</span>
                            </div>
                            <div class="">
                              Duration:
                              <span>{{ popup?.formatted_duration }}</span>
                            </div>
                          </div>
                        </l-popup>
                      </l-marker>
                    </template>
                  </template>
                </template>

                <template #polyline>
                  <l-polyline
                    :lat-lngs="mapData.path"
                    :color="'green'"
                  />
                </template>
              </vue-leaf-let>

              <template v-else-if="mapData.path.length == 0 && !isMap">
                <l-map
                  :zoom="zoom"
                  :center="center"
                  :dragging="false"
                  :zoom-control="false"
                  :scroll-wheel-zoom="false"
                  :touch-zoom="false"
                  :double-click-zoom="false"
                  style="height: 600px; width: 100%; cursor: default"
                >
                  <l-tile-layer
                    :url="url"
                    :attribution="attribution"
                  />
                </l-map>
              </template>
            </div>
          </div>
        </div>
      </template>

      <!-- description -->
    </ListingLayout>
  </div>
</template>

<script>
import service from "@/helpers/py-service";

import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";

import {
  LMarker,
  LIcon,
  LPopup,
  LPolyline,
  LMap,
  LTileLayer,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LPolyline,
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
    ApexSemiPieChart: () =>
      import("@/components/sections/ApexSemiPieChart.vue"),
    VueLeafLet: () => import("@/components/sections/VueLeafLet.vue"),
    VehicleMapMarkers: () => import("@/components/base/VehicleMapMarkers.vue"),
  },
  mixins: [ListingLayoutMixins],
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    selectedTab: {
      type: String,
      default() {
        return "";
      },
    },
    optionFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      zoom: 13,
      center: [24.8607, 67.0011], // Coordinates for the center of the map
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", // Tile layer URL
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      filters: {},
      subFilters: {},
      customFilter: {},
      //
      lastVehicleID: null,
      //
      listingLoading: true,
      meta: {},
      list: [],
      //
      detailsLoading: true,
      details: [],
      //
      isMap: true,
      mapData: {
        marker: [],
        path: [],
        event: {
          parked: [],
          idle: [],
        },
        vehicle_MT: "",
        vehicle_BT: "",
        status: "",
      },
      popup: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantList: "tenantsManagement/getTenantOptions",
    }),
    _headers() {
      let headerArr = [
        {
          text: this.$t("start_time"),
          align: "left",
          sortable: false,
          value: "start_time",
          // showAdmin: true,
        },
        {
          text: this.$t("end_time"),
          align: "left",
          sortable: false,
          value: "end_time",
          // showAdmin: true,
        },
        {
          text: this.$t("state"),
          align: "left",
          sortable: false,
          value: "state",
          // showAdmin: true,
        },
        {
          text: this.$t("total_distance"),
          align: "left",
          sortable: false,
          value: "total_distance",
          // showAdmin: true,
        },
        {
          text: this.$t("duration"),
          align: "left",
          sortable: false,
          value: "formatted_duration",
          // showAdmin: true,
        },

        {
          text: this.$t("address"),
          align: "left",
          sortable: false,
          value: "address",
          // showAdmin: true,
        },
      ];

      return headerArr;
    },

    transit() {
      const arr = [];
      const sum = this.details?.summary || [];
      if (sum.length > 0) {
        const r = sum.find((r) => r.State == "Transit");
        let obj = {
          name: r.State,
          data: r,
        };
        arr.push(obj);
      }
      return arr;
    },
    driving() {
      const arr = [];
      const sum = this.details?.summary || [];
      if (sum.length > 0) {
        const r = sum.find((r) => r.State == "Driving");
        let obj = {
          name: r.State,
          data: r,
        };
        arr.push(obj);
      }
      return arr;
    },
    idle() {
      const arr = [];
      const sum = this.details?.summary || [];
      if (sum.length > 0) {
        const r = sum.find((r) => r.State == "Idle");
        let obj = {
          name: r.State,
          data: r,
        };
        arr.push(obj);
      }
      return arr;
    },
    parked() {
      const arr = [];
      const sum = this.details?.summary || [];
      if (sum.length > 0) {
        const r = sum.find((r) => r.State == "Parked");
        let obj = {
          name: r.State,
          data: r,
        };
        arr.push(obj);
      }
      return arr;
    },

    lastParked() {
      const len = this.mapData?.event?.parked.length - 1;
      return [this.mapData?.event.parked[len]];
    },

    // for chart
    chartData() {
      let params = {
        titles: [],
        data: [],
        percentage: [],
      };
      if (this.details?.summary) {
        params.titles = this.details?.summary
          .filter((r) => r.State !== "Transit")
          .map((r) => r.State);
        params.data = this.details?.summary
          .filter((r) => r.State !== "Transit")
          .map((r) => r.total_duration_minutes);
        params.percentage = this.details?.summary
          .filter((r) => r.State !== "Transit")
          .map((r) => r.duration_percentage);
      }
      return params;
    },
  },
  watch: {
    "optionFilters.vehicle_id": {
      handler: async function (v) {
        if (this.name === this.selectedTab) {
          if (v) {
            this.options.page = 1;
            this.fetchData();
          }
        }
      },
    },
    selectedTab: {
      handler: async function (v) {
        this.isMap = true;
        setTimeout(() => (this.isMap = false), 0);
        if (v === this.name) {
          const vd = this.optionFilters.vehicle_id;
          if (vd != this.lastVehicleID) this.fetchData();
        }
      },
    },

    options() {
      this.fetchData();
    },
  },
  async mounted() {},
  beforeDestroy() {},

  methods: {
    async fetchData() {
      this.lastVehicleID = this.optionFilters.vehicle_id;
      this.listing();
      this.detailsData();
      this.getMapData();
    },
    setMetaData(data) {
      if (data.meta) {
        this.meta.page = data.meta.current_page;
        this.meta.itemsPerPage = data.meta.per_page;
        this.meta.lastPage = data.meta.last_page;
        this.meta.totalItems = data.meta.total;
      }
    },
    async listing() {
      const params = {
        ...this.options,
        ...this.customFilter,
        ...this.optionFilters,
      };
      this.listingLoading = true;
      await service
        .get(`vehicle/telematic-details-driving-usage-history`, params)
        .then((res) => {
          this.setMetaData(res.body);
          this.list = res.body.data;
        })
        .finally(() => (this.listingLoading = false));
    },

    async detailsData() {
      const params = {
        ...this.options,
        ...this.customFilter,
        ...this.optionFilters,
      };

      this.detailsLoading = true;
      await service
        .get(`vehicle/telematic-details-driving-journey-summary`, params)
        .then((res) => {
          this.details = res.body;
        })
        .finally(() => (this.detailsLoading = false));
    },
    async getMapData() {
      const params = {
        ...this.options,
        ...this.customFilter,
        ...this.optionFilters,
      };

      this.isMap = true;
      await service
        .get(`vehicle/telematic-details-driving-usage-map`, params)
        .then((res) => {
          const p = res.body;
          // vehcile model type
          this.mapData.vehicle_MT = p.vehicle_model_type;
          this.mapData.vehicle_BT = p.vehicle_model_body_type;
          this.mapData.status = p.vehicle_status;

          // For Polylines
          this.mapData.path = p?.complete_route.map((r) => [
            parseFloat(r.latitude),
            parseFloat(r.longitude),
          ]);
          // For Map Markers
          this.mapData.marker = [];
          if (this.mapData.path.length > 0) {
            let len = this.mapData.path.length - 1;
            this.mapData.marker.push(this.mapData.path[0]);
            this.mapData.marker.push(this.mapData.path[len]);
          }
          // For Events Markers
          const evt = p.event_data;

          this.mapData.event.parked = evt
            .filter((r) => r.state == "Parked")
            .map((r) => ({ ...r, points: [r.latitude, r.longitude] }));

          this.mapData.event.idle = evt
            .filter((r) => r.state == "Idle")
            .map((r) => ({ ...r, points: [r.latitude, r.longitude] }));
        })
        .finally(() => (this.isMap = false));
    },
    showPopupMarkersParked(event) {
      this.popup = {};
      // Get the coordinates of the clicked point
      const latlng = event.latlng;
      const detail = this.mapData.event.parked.find(
        (r) => r.latitude == latlng.lat && r.longitude == latlng.lng
      );
      // // Set the popup coordinates and content
      this.popup = detail;
    },
    showPopupMarkersIdle(event) {
      this.popup = {};
      // Get the coordinates of the clicked point
      const latlng = event.latlng;
      const detail = this.mapData.event.idle.find(
        (r) => r.latitude == latlng.lat && r.longitude == latlng.lng
      );
      // // Set the popup coordinates and content
      this.popup = detail;
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

.wrapper
  display: flex
  gap: 1rem
  >div
    flex: 1

.leftOverviewCard
  .overviewDetailsList
    list-style: none
    padding: 0
    margin: 0
    li
      margin-bottom: 1.45rem
      width: 100%
      border-bottom: 0
      &:nth-child(odd)
        margin-right: 0%
      .cardLabel
        font-family: $poppinMed
        color: #000
        font-size: 0.9375rem
        width: 100%
        padding: 0 0.75rem
        margin: 0 0 0.45rem 0
        display: flex
      .cardTextData
        color: #222
        display: inline-block
        font-weight: normal
        width: 50%

      ul
        margin: 0
        padding: 0
        width: 100% !important
        li
          margin-bottom: 0px
          width: 100% !important
          border-bottom: 1px solid #D9D9D9
          display: flex
          align-items: center
          min-height: 2.8rem
          padding: 0 0.75rem
          .cardLabel
            width: 50% !important
            margin:0
            font-family: $poppinReg
            font-size: 0.8125rem
            padding: 0
            margin: 0
            color: #767676
          .cardTextData
            width: 50% !important
            font-family: $poppinMed
            font-size: 0.8125rem
            color: #000
          &:nth-child(odd)
            background: #F5F5F5



.summaryRightCard
  display: flex
  flex-direction: column
  gap: 1rem
  >div:first-child
    height: auto !important

  .rightOverviewCard
    // height: 48.30%
    // margin-bottom: 4%
    &:last-child
      margin-bottom: 0
    .cardHeading
      width: 100%
    .semiPieChart
      width: 100%

:deep .leaflet-popup-content
  width: 200px !important
  .title
    font-weight: 600 !important
    font-size: large !important

@media only screen and (max-width: 960px)
  .wrapper
    flex-direction: column
</style>
